@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sue+Ellen+Francisco&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@500&family=Finger+Paint&family=Fredericka+the+Great&family=Kranky&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Istok+Web:wght@700&display=swap);
body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;

  -moz-osx-font-smoothing: grayscale;
}
html, body{
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cb-container {
  display:flex;
  justify-content: center;
  align-items: center;
  position:relative;
}

.cb-container-button {
  width: 200px;
  height: auto;
  margin: 20px 10px -8px 0px;
  outline:none;
  border:none;
  padding: 15px;
  font-size: 18px;
  color: grey;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 2px 0px 2px 0px grey
}
.cb-container-button:hover {
  cursor:pointer;
  background-color: #b8f2f7;
}
.cb-container-button:active {
  color: lightgrey;
}

.cb-count {
  border-radius:50%;
  width:30px;
  height:30px;
  border: 5px solid rgb(119 29 216);
  background-color: rgb(0 0 0);
  color:white;
  position:absolute;
  text-align:center;
  right:0px;
  top:3px;
  font-size:15px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: auto;
  border-radius: 8px 8px 0px 0px;
  padding: 1rem 0.5rem;
  background: #2a006e;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
  justify-content: center;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }

}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
@media(max-height:900px){
  .modal{
    top: 5vh;
  }
  .modal__footer{
    margin-top:15px;
  }
}
.text-area-styles {
  width: 500px;
  height: 50px;
  margin: 5px;
};
.new-name-list-container {
  display:flex;
  flex-direction:column;
}
.new-name-list {
  display:flex;

}
.new-class-link button{
  box-shadow: 5px 5px 7px rgb(33, 35, 51);
  border-radius:20px;
  width:200px;
  /* text-decoration:none; */

  border:3.2px solid rgb(38, 38, 110);
  color: rgb(34, 34, 94);
  font-weight:bold;
  outline:none;
  background-image: linear-gradient(60deg, rgb(214 238 255), rgb(186, 215, 235));
  padding:10px;
  border-radius:5px;
  font-size:1.3rem;

}
.new-class-link:hover button:hover{
  cursor:pointer;
  background-color:rgb(190, 250, 230);

  cursor:pointer;
  background-image:  linear-gradient(60deg, rgb(38, 38, 110),rgb(60, 60, 170)) ;
  color:white;
}

.new-class-container button {
  outline:none;
  width:200px;
  padding:20px;
  background-color:transparent;
}
.new-class-container button:hover {
  cursor:pointer
}
/* .create-class button {
  /* display:none; */
/* } */
.names-input-container {
  margin-top: 30px;
  height: 150px;
}
.new-class-container {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  /* min-height:100vh; */
  box-sizing:border-box;
}
.testing {
  background-color:rgb(50, 190, 200)
}

@media(max-width:900px){
  .new-class-container{
    max-height:300px;
  }
  .names-input-container{
    height:110px;
    margin-top:5px;
    display:flex;
  }

}
@media(max-width:500px){
  .text-area-styles{
    
    width: 250px;
  }
}
.navlinks-main-container{
  display:flex;
  justify-content:center;
  box-shadow:2px 2px 9px 3px grey;
  /* padding:3px; */
}
.switch-classes-button{
  padding:10px 10px;
  margin:10px 0px;

  text-decoration: none;
  font-size:1.5rem;
  font-family:'Montserrat';
  font-weight:bold;
  border:none;
  border-radius: 3px;

  background-color: #3f51b514;
    /* border: 2px ridge #28944f; */
  color: white;
  box-shadow: inset -2px -1px 5px rgb(16, 59, 16);
}
.switch-classes-text{
  display:block;
  transform: skewX(20deg);

}
.logout-signup-button {
  border-radius: 10px;
  border:none;
  background-color: #f1eaf352;
  padding:8px;
  box-shadow: inset -2px -1px 5px green;
  border-radius: 50%;
  width: 55px;
  border: 3px solid green;
  background-color: rgb(38 38 110);
  height: 55px;
  font-family: 'Quicksand';
  font-weight:600;
  padding: 10px;
  transform: skewX( 
10deg
)
}
.logout-signup-button a{
  font-size: 0.8rem;
  color:white;
  border-radius: 10px;
  /* background-color: #f1eaf352; */
  border: none;
  display:block;
}
.logout-signup-button:hover{
  cursor:pointer;
  box-shadow: inset 2px 1px 5px green;
  background-color: rgb(60, 60, 170);
}
.switch-classes-button:hover{
  cursor:pointer;
  box-shadow: inset 2px 1px 5px rgb(16, 59, 16);
}

.switch-classes-button:focus{
  outline:none;
}

.navlinks-container{
  width:auto;
  display:flex;
  flex:1 1;
  justify-content: space-between;
  
  align-items:flex-start;

}
.link-style{
  text-decoration: none;
  color:black;
}

.navbar-point-board{
  display:flex;
  align-items: center;
  justify-content: center;
  transform: skewX(15deg);
  background-color:rgb(60, 60, 170);
  width:25%;
  z-index: 1;
  font-weight: 100;
  border: 8px inset rgb(117 182 187);
  font-family: 'Sue Ellen Francisco';
  background-image: linear-gradient(45deg, transparent, rgb(34, 34, 94))
}
.navbar-point-board h5{
  color:white;
  font-size:2.5rem;
  margin:0;
  display:block;
  transform:skewX(-15deg)

}
.classes-count {
  border-radius:50%;
  width: auto;
  min-width: 45px;
  margin-left:10px;
  /* border: 5px solid rgb(119 29 216); */
  border: 3px ridge rgb(241 237 245);
  background-color: rgb(0 0 0 / 33%);
  color:white;
  text-align:center;
  font-size:25px;
  display:block;
  transform:skewX(-15deg)
}
.navbar-button-board{
  z-index: 1;
  width: 25%;
  border: 8px inset rgb(117 182 187);
  background-color: #5db162;
  transform: skewX(-15deg)
}

@media(max-width:1300px){
  .navbar-point-board h5{
    font-size:1.8rem;
  }
  .navbar-point-board {
    transform: skewX(10deg);

  }
  .navbar-button-board{
  transform: skewX(-10deg)

  }

}

@media(max-width:900px){

  .navbar-point-board h5{
    font-size:1.5rem;
    font-weight:lighter;

  }
  .classes-count{
    font-size:1.3rem;
    min-width:30px;
    border:1px ridge rgb(241 237 245);
  }
  .switch-classes-button{
    font-size:1rem;
  }
  .navlinks-main-container{
    height: 70px;
  }
}

@media(max-width:680px){
  .logout-signup-button{
    transform:none;
    height: 40px;
    width: 40px;
    padding: 3px;
    align-self: flex-end;
  }
  .link-style{
    font-size:0.6rem;
  }
  .navbar-button-board{
    
  }
  .navbar-point-board{
    font-size:1rem;
    transform:skewX(5deg)
    /* width:35%; */
  }
  .navbar-point-board h5{
    line-height: 1.2;
    padding-left:15px;
    transform:skewX(-1deg)
  }
  .classes-count{
    transform:skewX(1deg)
  }
  .navlinks-main-container{
    height:100px
  }
  .navlinks-container{
    display:flex;
    flex-direction:column-reverse;

  }
  .switch-classes-button{
    margin-top:5px;
  }
  .switch-classes-text{
  transform: skewX(10deg);

  }
  .logout-signup-button a{
    transform:skewX(5deg)
  }


}
.logo-container {
  display:flex;
  width: 140px;
  height: 140px;
  align-items:flex-end;
  border-radius: 50%;
  background:white;
  border: 5px solid black;
  /* border-left: 5px solid purple; */
  
  box-shadow: 2px 3px 7px 3px rgb(5, 51, 33); 
  padding-right:18px;
  padding-left:18px;
  padding-bottom:30px;
  margin-right:15px;
  /* width:120px;
  height: 120px; */
}
.logo-heading {
  /* opacity:100%; */
  display:flex;
  

}
.logo-container:hover {
  /* background-image: radial-gradient(circle, transparent 40%, #fffac4, #fffde6, white);
  background-color:yellow;
  transition: background 1s;
  transition-timing-function: ease-in; */
}
.logo-end {
  font-size:5em;
  line-height:3em;
  font-family:'Istok Web', serif

}
.logo-student-card-container {
  display:flex;
  flex-direction:column;
  align-items:center;
  height:100%;
  width: 100%;
 }

 .logo-student-icon-container {
   display:flex;
   flex-direction:column;
   justify-content:flex-end;
   align-items:center;
   height:50%;
   width:100%;
 }
 .logo-student-head {
   background-color:rgb(0, 0, 0);
   background-image: radial-gradient(circle at top left, rgb(95, 94, 94) 10%, black );

   height:50%;
   width: 30%;
   border-radius:50%;
   margin-bottom:2px;
 }
 .logo-student-head-button-container {
   display:flex;
 }
 .logo-student-body {
   background-color: black;
   background-image: radial-gradient(circle at top left, rgb(95, 94, 94) 10%, black );
   height: 23%;
   width: 60%;
   border-radius: 50px 50px 0% 0%
 }



/* Desk & Desk Buttons */
 .logo-desk-top {
   height:10%;
   color:rgb(0, 0, 0);
   text-align:center;
   font-size:20px;
   font-weight:bold;
   width:100%;
   /* opacity:130%; */
   border-radius:5px;
   background-color:rgb(62, 126, 96);
   background-color: #44e69a;
 }
 .logo-desk {
   height:40%;
   width:82%;
   /* opacity:80%; */
   background-color:rgb(145, 85, 173);
   background-color:#383cab;
   color:white;
   text-align:center;
   font-weight:bold;
   font-size:20px;
   margin:0 9px 0px 9px;
   border-bottom-left-radius:3px;
   border-bottom-right-radius:3px;
   box-shadow:5px 3px 7px 2px grey;
 }

@media(max-width:800px){
  .logo-end{
    font-size: 3rem;
    line-height: 1.4em;
   }
   .logo-container{
    width: 100px;
    height: 100px;
    padding-bottom: 20px;
    margin-left: 20px;
   }
}

.student-name-points-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display:flex;
  color: white;
  font-size: 30px;
  justify-content:center;
  background-color: black;

}
.student-card-points {
  /* flex-grow:1; */
  text-align:center;
  font-size:55px;
  font-weight:bold;
  /* justify-content:center; */
  align-self:center;

}

.student-card-popup {
  display:flex;
  justify-content:center;
  align-items:center;
  position:relative;
  bottom:0;
}

.student-head {
  background-color:black;
  background-image: radial-gradient(circle at top left, rgb(95, 94, 94) 10%, black );
  height:65%;
  width:30%;
  border-radius:50%;
  margin-bottom:2px;
}
.student-head-button-container {
  display:flex;
  width:100%;
  height:100%;
  align-items:flex-end;
  position:relative;
  justify-content:center;
}
.student-body {
  background-color: black;
  background-image: radial-gradient(circle at top left, rgb(95, 94, 94) 10%, black );

  height: 28%;
  width: 65%;
  border-radius: 50px 50px 0% 0%
}
.pts-button-container{
  position:absolute;
  display:flex;
  height:100%;
  width:100%;
  align-items:flex-start;
  justify-content:space-between;
}
.pts-buttons {
 display:none;
 max-height:20px;
 height:20px;
}

.student-card-container:hover .pts-buttons{
 display:inline;
}




.student-card-count{
  flex-grow:1;
  position:absolute;
}

.student-count-style-positive{
  color: #082308;
  background-image: radial-gradient(#8ee28e, #8ee28e45, transparent);
  border-radius: 50%;
  width: 30px;
}
.student-count-style-negative{

  background-image: radial-gradient(#ff7a70, #ff7a7080, transparent);
  color: black;
  border-radius: 50%;
  width: 30px
}
.student-count-style-neutral{
  /* text-shadow: 2px 2px 10px #a7aba76b; */
  color: #265426;
}

.desk-button-main-container {
  display:flex;
  /* justify-content:center; */
  position:relative;
  /* width: 160px; */
  justify-content: center;
  align-items: end;
}
.desk-button-main-container p{
  /* background-image: radial-gradient(#59ff5973, transparent); */
  margin: 0;
}

.desk-button-container {
  /* display:none; */
  max-height:20%;
  height:20%;
  position:absolute;
  /* margin-left:-55%; */
  width: 100%;
  /* margin-left: -10px; */
  display: flex;
  justify-content: flex-end;
  align-items: end
  
 }
 .student-card-container:hover .desk-button-container{
  justify-content: space-between
 
 }
.desk-button {
  display:none;
  margin-left: -10%;
  margin-right:-20%
}

.student-card-container:hover .desk-button{
 display:inline;
}
.student-name{
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  padding-bottom: 0;
  overflow: hidden;
}
.name-large{
  font-size: 24px;
}
.name-medium{
  font-size: 22px;

}
.name-small{
  font-size: 18px;

}
.name-xsmall{
  font-size: 17px;

}
.name-xxsmall{
  font-size: 16px;

}

@media(max-width:900px){
  .student-head-button-container{
    width:120%
  }

  .desk{
    font-size:1rem;
  }
  .name-large{
    font-size: 17px;
  }
  .name-medium{
    font-size: 16px;
  
  }
  .name-small{
    font-size:15px;
  }
  .name-xsmall{
    font-size:14px;
  }
  .name-xxsmall{
    font-size:13px;
  }
  .desk-button-main-container{
    margin-bottom:32px;
    margin-top:-10px;
  }
  .desk-button-main-container p{
    margin-top:10px;
    font-size: 14px;
  }
  .student-card-container{
    height: 95px;
    width:95px;
  }

}
.classes-main-container{
  
}
.classes-container {
  background: url(/static/media/tan-floor.4ee00789.jpg); 
  background-repeat: repeat;
  background-size: 700px 500px;
  height:auto;
  /* background: url("../app-files/images/warmwood-background.jpg"); 
  background-repeat: repeat;
  background-size: 500px 300px;

  background: url("../app-files/images/tantile-background.jpg"); 
  background-repeat: repeat;
  background-size: 100px 80px; */

}
.classes-container h1{
  text-align:center;
}
.no-classes-container{
  margin-top:100px;


}
.no-classes-container h1{
  text-align:center;
}
.no-classes-logo-container{
  display:flex;
  justify-content:center;
  width:100vw;
}
.droppable-container{
  display: flex;
  margin: 0px 50px 50px 50px;
}

.row-container {
  display:flex;
  flex-direction:column;
  
}
.row-main-container {
  display:flex;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 70px;
  background-image:  linear-gradient( 
    45deg
     ,rgb(255 255 255 / 90%), rgb(255 255 255 / 90%), rgb(255 255 255 / 91%))

}
.group-main-container {
  height: calc(100vh - 121px);
  padding-bottom: 70px;
  background-image:  linear-gradient( 
    45deg
     ,rgb(255 255 255 / 90%), rgb(255 255 255 / 90%), rgb(255 255 255 / 91%))
}
.row {
  display:flex;
  justify-content:flex-start;

}
.group7 {
  display:flex;
  flex-direction:column;
  height:550px;
  margin:20px 30px 10px 30px;
  width:300px;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items:flex-start;
}
.group-container7 {
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  width:100%;

}
.group4 {
  display:flex;
  flex-direction:column;
  padding:10px 0px 0px 0px;
  justify-content:flex-start;
  align-items:flex-start;

}
.group-container4 {
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  padding-bottom:10px;

}
.group5, .group6 {
  display:flex;
  flex-direction:column;
  min-width: 145px;
  padding:10px 0px 0px 0px;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items:flex-start;
}
.group-container5, .group-container6 {
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  padding-bottom:50px;

}



.small-icon{
  height:130px !important;
  width:120px !important;
}
.small-buttons {
  margin-left: -10% !important;
  margin-right:-27% !important;
}
.small-font {
  font-size:1rem !important;
}
.small-group4 {
  /* height:300px !important; */
  /* width:250px !important; */
  /* margin:0px 30px !important; */
}
.small-group5, .small-group6 {
  height:390px !important;
  /* width:250px !important; */
  /* margin:0px 30px 30px 30px !important; */
}
.small-group7{
  height:630px !important;
  width:250px !important;
  /* margin:0px 30px !important; */
}
.select-form{
  width:120px !important;
}


.student-card-container { /* changes style under card */
  width:145px;
  height:150px;
  padding: 0px;
  display: flex;
  flex-direction:column;
  align-items:center;
}

.classes-cb-list-container{
  display:flex;
  justify-content:center;
  width:1500px;
  overflow-y:visible;
}

.temp-container {
  margin-bottom:100px
}


/* studentCard */
.drag{
  width:100%;
  height:100%;
}
 .student-icon-container {
   display:flex;
   height:50%;
   flex-direction:column;
   justify-content:center;
   align-items:center;
   background-color: transparent;
   width:100%;

 }



/* Desk & Desk Buttons */
 .desk-top {
   height:6%;
   color:rgb(0, 0, 0);
   text-align:center;
   font-size:20px;
   font-weight:bold;
   width:93%;
   border: 3px outset rgb(95 94 94 / 44%);
   /* opacity:130%; */
   border-radius:7px;
   background-color:rgb(77, 63, 63)
 }
 .blank-desk-top {
   background-color:rgb(209, 208, 208);
   /* border:2px solid grey; */
 }
 .desk {
   display:block;
   height:45%;
   width:85%;
   /* opacity:80%; */
   background-color:rgb(149, 181, 223);
   color:black;
   text-align:center;
   font-weight:bold;
   font-size:20px;
   margin:0 9px 0px 9px;
   border-bottom-left-radius:3px;
   border-bottom-right-radius:3px;
   border-right: 3px outset #80808063;
   border-left: 3px outset #80808063;
   border-bottom: 3px outset #80808063;
   white-space: nowrap;
   box-shadow:5px 3px 7px 2px grey;
   font-family:'Quicksand';

 }

 .blank-desk {
  background-color:rgb(248, 252, 255);
  /* border:2px solid grey; */
  box-shadow:3px 2px 7px 2px rgb(177, 175, 175);
 }

.testing {
  background-color:rgb(117, 117, 117)
}

.addNewStu-modal{
  display: flex;
}

.transition-classes-enter {
  opacity: 0;
}
.transition-classes-active {
  opacity: 1;
  transition: opacity 200ms;
}
.transition-classes-exit {
  opacity: 1;
}
.transition-classes-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

@media(max-width:900px){
  .droppable-container{
    margin: 0px 5px 15px 5px
  }
  .small-icon{
    width:95px!important;
    height:95px!important;
  }
  .group56{
    min-width:100px;
  }
  .group7{
    width:100px;
    min-width:100px;
    margin:0px;
    height:415px;
  }
  .addNewStu-modal{
    padding:0rem;
  }
  .group-container4{
    padding-bottom:50px;
  }
  .classes-container{
    padding-bottom: 50px;
  }
}

@media(max-width:500px){

}
.classes-title-menu-container{
  display:flex;
  background-color:white;
  justify-content:center;
  align-items:center;
  min-width:600px
  /* transform:skewX(10deg); */

}
.classes-title-menu-container h1{
  width: 100%;
  margin: 10px;
  font-size: 3.5rem;
  text-align: center;
  font-family: 'Caveat'
}
.classes-chalkboard-border{
  /* position:absolute;
  
  border-top:130px solid rgb(110, 76, 44);
  border-left:30px solid;
  border-right:30px solid;
  height:0; */
}
.classes-chalkboard-container{
  /* border:solid 4px rgb(136, 96, 59); */
  position: relative;
  color: white;
  width: 100%;
  height: 85%;
  background-color: #031507;
  border: 8px ridge#6b450f;
  transform: perspective(200px) rotateX(-10deg);
  background-image: linear-gradient(
    180deg
    , transparent, #3a3c39db);
}
.classes-chalkboard-container:before{

  /* background-color:black; */
}
.classes-chalkboard-title-menu{
  display:flex;
  align-items: flex-start;
  position:relative;
  justify-content: space-between;
}

.classes-title-menu-chalkboard h1{
  transform:skewX(0deg)
}
.classes-title-menu-chalkboard::before{
  content: ""; display: block; position: absolute; top: 0; left: 0;
  z-index:-1
}




.add-student-button-container{
  display:flex;
  justify-content: center;
  align-items: center;
}
.add-student-button {
  border:3.2px solid rgb(38, 38, 110);
  color: rgb(34, 34, 94);
  font-weight:bold;
  outline:none;
  background-image: linear-gradient(60deg, rgb(214 238 255), rgb(186, 215, 235));
  padding:10px;
  border-radius:5px;

}
.add-student-button:hover{
  cursor:pointer;
  background-image:  linear-gradient(60deg, rgb(38, 38, 110),rgb(60, 60, 170)) ;
  color:white;
}

@media(max-width:1300px){
  .classes-title-menu-container{
    min-width:400px;
  }
}
@media(max-width:900px){
  .classes-title-menu-container{
    min-width:300px;
  }
  .classes-title-menu-container h1{
    font-size:2.4rem;

  }
}

@media(max-width:500px){
  .classes-title-menu-container{
    min-width:180px;
  }
  .classes-title-menu-container h1{
    font-size:1.7rem;
    font-weight:200;

  }
}
.random-student {
  display:flex;
  justify-content:center;
  align-items:center;
  height:200px;
  width:auto;
  font-size:70px;
}
.random-student-header {
  width:auto;
}
.random-student-button{
  display:flex;
  justify-content:center;
  align-items:center;
  
}
.random-student-content-modal{
  padding:0px;
  margin-right:2px;
  font-weight:bold;
  box-shadow:2px 3px 7px 2px rgb(44, 37, 37);
}
.gcb-container {
  display:flex;
  position:fixed;
  bottom:0;
  width:100vw;
  flex-wrap:wrap;
  justify-content:center;
  font-size:12px;
  align-items:center;
  background-color:rgb(118, 195, 209);
}
.icon-button-text{
  font-size:0.9rem;
}

@media(max-width:500px){
  .multi-select{
    display: flex;
    overflow: overlay;
  }
}

.home-main-container {
  display:flex;

  flex-direction:column;

}
.home-container {
  display:flex;
  background: #68114e;
  background: url(/static/media/desks2.80aafe6e.jpg);
  /* background-image: linear-gradient(transparent, #662a61); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position:50%;
  width:100vw;
  height:100vh;
  /* background-image:linear-gradient(45deg,rgb(46, 80, 60), black, #905590); */
  color:white;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding-top:0px;
}
.home-container h3 {
  padding-top:0px;
  font-size:1.4rem;
  -webkit-animation: moveInRight 2s ease-out;
          animation: moveInRight 2s ease-out;

}
.home-container h5 {
  opacity:70%;
  padding:10px;
  color:white;

}

.link {
  background-color:rgb(255, 255, 255);
  opacity:0.7;
  font-size:1.5rem;
  padding:10px;
  color:black;
  border: none;
  border-radius:10px;
  text-decoration:none;
  margin-right:5px;
}
.link:hover{
  opacity:0.85;
  cursor:pointer;
}
.link-container {
  display:flex;
}
.logo-c{
  font-size:75px;
  line-height:60px;
  font-family:'Istok Web', serif
}

 .home-main-container img{
   width:35vw;
   height:auto;
 }
 .home-description-container {
   display:flex;
   margin:20px;
   padding:2% 15%;

 }
 .home-description-container p {
   margin:20px;
   font-size: 1.6rem;
 }
 .home-container-gradient{
   display:flex;
   flex-direction:column;
   justify-content:center;
   align-items:center;
   position:absolute;
  background-image:linear-gradient(45deg,rgb(46, 80, 60, 80%), rgb(0, 0, 0, 80%), rgb(144, 85, 144, 80%));
  background-image:linear-gradient(45deg,rgb(48, 88, 65, 80%),rgb(0, 0, 0, 80%),rgb(97, 90, 195, 79%));
  height:100vh;
  width:100vw;
 }

 @-webkit-keyframes moveInLeft {
   0% {
      opacity: 0;
      transform: translateX(-100px);
   }
   /* 80% {
      transform: translateX(10px);
   } */
   100% {
      opacity:1;
      transform: translate(0);
   }
 }

 @keyframes moveInLeft {
   0% {
      opacity: 0;
      transform: translateX(-100px);
   }
   /* 80% {
      transform: translateX(10px);
   } */
   100% {
      opacity:1;
      transform: translate(0);
   }
 }
 @-webkit-keyframes moveInRight {
  0% {
     opacity: 0;
     transform: translateX(100px);
  }
  /* 80% {
     transform: translateX(-10px);
  } */
  100% {
     opacity:1;
     transform: translate(0);
  }
}
 @keyframes moveInRight {
  0% {
     opacity: 0;
     transform: translateX(100px);
  }
  /* 80% {
     transform: translateX(-10px);
  } */
  100% {
     opacity:1;
     transform: translate(0);
  }
}

@media(max-width:600px){
  .home-container{
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #226672;
  border-radius: 4px;
  background: #226672;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #277785;
  border-color: #277785;
}

.button--inverse {
  background: transparent;
  color: #226672;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #226672;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #226672;
  border-color: #226672;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
.card {
  position: relative;
  margin: 10px 0 0 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.authenticate-container {
  min-height:100vh;
  width: 100vw;
  box-sizing:border-box;
  padding:50px;

}
.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 7rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}
.main-container {
  height:100vh;
}


.blank-student {
  width:180px;
  height:180px;
  /* padding:0px 10px 0px 10px; */
  box-shadow: 10px 10px 10px grey;
  border-radius: 20px;
}

.grid-row {
  display:flex;
  justify-content:center;
}
.name-display-container {
  display:flex;
  flex-direction:column;
}
.name-display-row {
  display:flex;
}
.icon {
  padding:5px;
}

