.cb-container {
  display:flex;
  justify-content: center;
  align-items: center;
  position:relative;
}

.cb-container-button {
  width: 200px;
  height: auto;
  margin: 20px 10px -8px 0px;
  outline:none;
  border:none;
  padding: 15px;
  font-size: 18px;
  color: grey;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 2px 0px 2px 0px grey
}
.cb-container-button:hover {
  cursor:pointer;
  background-color: #b8f2f7;
}
.cb-container-button:active {
  color: lightgrey;
}

.cb-count {
  border-radius:50%;
  width:30px;
  height:30px;
  border: 5px solid rgb(119 29 216);
  background-color: rgb(0 0 0);
  color:white;
  position:absolute;
  text-align:center;
  right:0px;
  top:3px;
  font-size:15px;
}