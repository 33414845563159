.text-area-styles {
  width: 500px;
  height: 50px;
  margin: 5px;
};
.new-name-list-container {
  display:flex;
  flex-direction:column;
}
.new-name-list {
  display:flex;

}
.new-class-link button{
  box-shadow: 5px 5px 7px rgb(33, 35, 51);
  border-radius:20px;
  width:200px;
  /* text-decoration:none; */

  border:3.2px solid rgb(38, 38, 110);
  color: rgb(34, 34, 94);
  font-weight:bold;
  outline:none;
  background-image: linear-gradient(60deg, rgb(214 238 255), rgb(186, 215, 235));
  padding:10px;
  border-radius:5px;
  font-size:1.3rem;

}
.new-class-link:hover button:hover{
  cursor:pointer;
  background-color:rgb(190, 250, 230);

  cursor:pointer;
  background-image:  linear-gradient(60deg, rgb(38, 38, 110),rgb(60, 60, 170)) ;
  color:white;
}

.new-class-container button {
  outline:none;
  width:200px;
  padding:20px;
  background-color:transparent;
}
.new-class-container button:hover {
  cursor:pointer
}
/* .create-class button {
  /* display:none; */
/* } */
.names-input-container {
  margin-top: 30px;
  height: 150px;
}
.new-class-container {
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  /* min-height:100vh; */
  box-sizing:border-box;
}
.testing {
  background-color:rgb(50, 190, 200)
}

@media(max-width:900px){
  .new-class-container{
    max-height:300px;
  }
  .names-input-container{
    height:110px;
    margin-top:5px;
    display:flex;
  }

}
@media(max-width:500px){
  .text-area-styles{
    
    width: 250px;
  }
}