
.authenticate-container {
  min-height:100vh;
  width: 100vw;
  box-sizing:border-box;
  padding:50px;

}
.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 7rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}