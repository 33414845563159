.random-student {
  display:flex;
  justify-content:center;
  align-items:center;
  height:200px;
  width:auto;
  font-size:70px;
}
.random-student-header {
  width:auto;
}
.random-student-button{
  display:flex;
  justify-content:center;
  align-items:center;
  
}
.random-student-content-modal{
  padding:0px;
  margin-right:2px;
  font-weight:bold;
  box-shadow:2px 3px 7px 2px rgb(44, 37, 37);
}
.gcb-container {
  display:flex;
  position:fixed;
  bottom:0;
  width:100vw;
  flex-wrap:wrap;
  justify-content:center;
  font-size:12px;
  align-items:center;
  background-color:rgb(118, 195, 209);
}
.icon-button-text{
  font-size:0.9rem;
}

@media(max-width:500px){
  .multi-select{
    display: flex;
    overflow: overlay;
  }
}