
@import url('https://fonts.googleapis.com/css2?family=Istok+Web:wght@700&display=swap');
.home-main-container {
  display:flex;

  flex-direction:column;

}
.home-container {
  display:flex;
  background: #68114e;
  background: url("../app-files/images/desks2.jpg");
  /* background-image: linear-gradient(transparent, #662a61); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position:50%;
  width:100vw;
  height:100vh;
  /* background-image:linear-gradient(45deg,rgb(46, 80, 60), black, #905590); */
  color:white;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding-top:0px;
}
.home-container h3 {
  padding-top:0px;
  font-size:1.4rem;
  animation: moveInRight 2s ease-out;

}
.home-container h5 {
  opacity:70%;
  padding:10px;
  color:white;

}

.link {
  background-color:rgb(255, 255, 255);
  opacity:0.7;
  font-size:1.5rem;
  padding:10px;
  color:black;
  border: none;
  border-radius:10px;
  text-decoration:none;
  margin-right:5px;
}
.link:hover{
  opacity:0.85;
  cursor:pointer;
}
.link-container {
  display:flex;
}
.logo-c{
  font-size:75px;
  line-height:60px;
  font-family:'Istok Web', serif
}

 .home-main-container img{
   width:35vw;
   height:auto;
 }
 .home-description-container {
   display:flex;
   margin:20px;
   padding:2% 15%;

 }
 .home-description-container p {
   margin:20px;
   font-size: 1.6rem;
 }
 .home-container-gradient{
   display:flex;
   flex-direction:column;
   justify-content:center;
   align-items:center;
   position:absolute;
  background-image:linear-gradient(45deg,rgb(46, 80, 60, 80%), rgb(0, 0, 0, 80%), rgb(144, 85, 144, 80%));
  background-image:linear-gradient(45deg,rgb(48, 88, 65, 80%),rgb(0, 0, 0, 80%),rgb(97, 90, 195, 79%));
  height:100vh;
  width:100vw;
 }

 @keyframes moveInLeft {
   0% {
      opacity: 0;
      transform: translateX(-100px);
   }
   /* 80% {
      transform: translateX(10px);
   } */
   100% {
      opacity:1;
      transform: translate(0);
   }
 }
 @keyframes moveInRight {
  0% {
     opacity: 0;
     transform: translateX(100px);
  }
  /* 80% {
     transform: translateX(-10px);
  } */
  100% {
     opacity:1;
     transform: translate(0);
  }
}

@media(max-width:600px){
  .home-container{
    background-size: cover;
    background-repeat: no-repeat;
  }
}