@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&family=Finger+Paint&family=Fredericka+the+Great&family=Kranky&display=swap');
.classes-title-menu-container{
  display:flex;
  background-color:white;
  justify-content:center;
  align-items:center;
  min-width:600px
  /* transform:skewX(10deg); */

}
.classes-title-menu-container h1{
  width: 100%;
  margin: 10px;
  font-size: 3.5rem;
  text-align: center;
  font-family: 'Caveat'
}
.classes-chalkboard-border{
  /* position:absolute;
  
  border-top:130px solid rgb(110, 76, 44);
  border-left:30px solid;
  border-right:30px solid;
  height:0; */
}
.classes-chalkboard-container{
  /* border:solid 4px rgb(136, 96, 59); */
  position: relative;
  color: white;
  width: 100%;
  height: 85%;
  background-color: #031507;
  border: 8px ridge#6b450f;
  transform: perspective(200px) rotateX(-10deg);
  background-image: linear-gradient(
    180deg
    , transparent, #3a3c39db);
}
.classes-chalkboard-container:before{

  /* background-color:black; */
}
.classes-chalkboard-title-menu{
  display:flex;
  align-items: flex-start;
  position:relative;
  justify-content: space-between;
}

.classes-title-menu-chalkboard h1{
  transform:skewX(0deg)
}
.classes-title-menu-chalkboard::before{
  content: ""; display: block; position: absolute; top: 0; left: 0;
  z-index:-1
}




.add-student-button-container{
  display:flex;
  justify-content: center;
  align-items: center;
}
.add-student-button {
  border:3.2px solid rgb(38, 38, 110);
  color: rgb(34, 34, 94);
  font-weight:bold;
  outline:none;
  background-image: linear-gradient(60deg, rgb(214 238 255), rgb(186, 215, 235));
  padding:10px;
  border-radius:5px;

}
.add-student-button:hover{
  cursor:pointer;
  background-image:  linear-gradient(60deg, rgb(38, 38, 110),rgb(60, 60, 170)) ;
  color:white;
}

@media(max-width:1300px){
  .classes-title-menu-container{
    min-width:400px;
  }
}
@media(max-width:900px){
  .classes-title-menu-container{
    min-width:300px;
  }
  .classes-title-menu-container h1{
    font-size:2.4rem;

  }
}

@media(max-width:500px){
  .classes-title-menu-container{
    min-width:180px;
  }
  .classes-title-menu-container h1{
    font-size:1.7rem;
    font-weight:200;

  }
}