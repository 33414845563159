.logo-container {
  display:flex;
  width: 140px;
  height: 140px;
  align-items:flex-end;
  border-radius: 50%;
  background:white;
  border: 5px solid black;
  /* border-left: 5px solid purple; */
  
  box-shadow: 2px 3px 7px 3px rgb(5, 51, 33); 
  padding-right:18px;
  padding-left:18px;
  padding-bottom:30px;
  margin-right:15px;
  /* width:120px;
  height: 120px; */
}
.logo-heading {
  /* opacity:100%; */
  display:flex;
  

}
.logo-container:hover {
  /* background-image: radial-gradient(circle, transparent 40%, #fffac4, #fffde6, white);
  background-color:yellow;
  transition: background 1s;
  transition-timing-function: ease-in; */
}
.logo-end {
  font-size:5em;
  line-height:3em;
  font-family:'Istok Web', serif

}
.logo-student-card-container {
  display:flex;
  flex-direction:column;
  align-items:center;
  height:100%;
  width: 100%;
 }

 .logo-student-icon-container {
   display:flex;
   flex-direction:column;
   justify-content:flex-end;
   align-items:center;
   height:50%;
   width:100%;
 }
 .logo-student-head {
   background-color:rgb(0, 0, 0);
   background-image: radial-gradient(circle at top left, rgb(95, 94, 94) 10%, black );

   height:50%;
   width: 30%;
   border-radius:50%;
   margin-bottom:2px;
 }
 .logo-student-head-button-container {
   display:flex;
 }
 .logo-student-body {
   background-color: black;
   background-image: radial-gradient(circle at top left, rgb(95, 94, 94) 10%, black );
   height: 23%;
   width: 60%;
   border-radius: 50px 50px 0% 0%
 }



/* Desk & Desk Buttons */
 .logo-desk-top {
   height:10%;
   color:rgb(0, 0, 0);
   text-align:center;
   font-size:20px;
   font-weight:bold;
   width:100%;
   /* opacity:130%; */
   border-radius:5px;
   background-color:rgb(62, 126, 96);
   background-color: #44e69a;
 }
 .logo-desk {
   height:40%;
   width:82%;
   /* opacity:80%; */
   background-color:rgb(145, 85, 173);
   background-color:#383cab;
   color:white;
   text-align:center;
   font-weight:bold;
   font-size:20px;
   margin:0 9px 0px 9px;
   border-bottom-left-radius:3px;
   border-bottom-right-radius:3px;
   box-shadow:5px 3px 7px 2px grey;
 }

@media(max-width:800px){
  .logo-end{
    font-size: 3rem;
    line-height: 1.4em;
   }
   .logo-container{
    width: 100px;
    height: 100px;
    padding-bottom: 20px;
    margin-left: 20px;
   }
}
