@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sue+Ellen+Francisco&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

.navlinks-main-container{
  display:flex;
  justify-content:center;
  box-shadow:2px 2px 9px 3px grey;
  /* padding:3px; */
}
.switch-classes-button{
  padding:10px 10px;
  margin:10px 0px;

  text-decoration: none;
  font-size:1.5rem;
  font-family:'Montserrat';
  font-weight:bold;
  border:none;
  border-radius: 3px;

  background-color: #3f51b514;
    /* border: 2px ridge #28944f; */
  color: white;
  box-shadow: inset -2px -1px 5px rgb(16, 59, 16);
}
.switch-classes-text{
  display:block;
  transform: skewX(20deg);

}
.logout-signup-button {
  border-radius: 10px;
  border:none;
  background-color: #f1eaf352;
  padding:8px;
  box-shadow: inset -2px -1px 5px green;
  border-radius: 50%;
  width: 55px;
  border: 3px solid green;
  background-color: rgb(38 38 110);
  height: 55px;
  font-family: 'Quicksand';
  font-weight:600;
  padding: 10px;
  transform: skewX( 
10deg
)
}
.logout-signup-button a{
  font-size: 0.8rem;
  color:white;
  border-radius: 10px;
  /* background-color: #f1eaf352; */
  border: none;
  display:block;
}
.logout-signup-button:hover{
  cursor:pointer;
  box-shadow: inset 2px 1px 5px green;
  background-color: rgb(60, 60, 170);
}
.switch-classes-button:hover{
  cursor:pointer;
  box-shadow: inset 2px 1px 5px rgb(16, 59, 16);
}

.switch-classes-button:focus{
  outline:none;
}

.navlinks-container{
  width:auto;
  display:flex;
  flex:1;
  justify-content: space-between;
  
  align-items:flex-start;

}
.link-style{
  text-decoration: none;
  color:black;
}

.navbar-point-board{
  display:flex;
  align-items: center;
  justify-content: center;
  transform: skewX(15deg);
  background-color:rgb(60, 60, 170);
  width:25%;
  z-index: 1;
  font-weight: 100;
  border: 8px inset rgb(117 182 187);
  font-family: 'Sue Ellen Francisco';
  background-image: linear-gradient(45deg, transparent, rgb(34, 34, 94))
}
.navbar-point-board h5{
  color:white;
  font-size:2.5rem;
  margin:0;
  display:block;
  transform:skewX(-15deg)

}
.classes-count {
  border-radius:50%;
  width: auto;
  min-width: 45px;
  margin-left:10px;
  /* border: 5px solid rgb(119 29 216); */
  border: 3px ridge rgb(241 237 245);
  background-color: rgb(0 0 0 / 33%);
  color:white;
  text-align:center;
  font-size:25px;
  display:block;
  transform:skewX(-15deg)
}
.navbar-button-board{
  z-index: 1;
  width: 25%;
  border: 8px inset rgb(117 182 187);
  background-color: #5db162;
  transform: skewX(-15deg)
}

@media(max-width:1300px){
  .navbar-point-board h5{
    font-size:1.8rem;
  }
  .navbar-point-board {
    transform: skewX(10deg);

  }
  .navbar-button-board{
  transform: skewX(-10deg)

  }

}

@media(max-width:900px){

  .navbar-point-board h5{
    font-size:1.5rem;
    font-weight:lighter;

  }
  .classes-count{
    font-size:1.3rem;
    min-width:30px;
    border:1px ridge rgb(241 237 245);
  }
  .switch-classes-button{
    font-size:1rem;
  }
  .navlinks-main-container{
    height: 70px;
  }
}

@media(max-width:680px){
  .logout-signup-button{
    transform:none;
    height: 40px;
    width: 40px;
    padding: 3px;
    align-self: flex-end;
  }
  .link-style{
    font-size:0.6rem;
  }
  .navbar-button-board{
    
  }
  .navbar-point-board{
    font-size:1rem;
    transform:skewX(5deg)
    /* width:35%; */
  }
  .navbar-point-board h5{
    line-height: 1.2;
    padding-left:15px;
    transform:skewX(-1deg)
  }
  .classes-count{
    transform:skewX(1deg)
  }
  .navlinks-main-container{
    height:100px
  }
  .navlinks-container{
    display:flex;
    flex-direction:column-reverse;

  }
  .switch-classes-button{
    margin-top:5px;
  }
  .switch-classes-text{
  transform: skewX(10deg);

  }
  .logout-signup-button a{
    transform:skewX(5deg)
  }


}