@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

.student-name-points-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display:flex;
  color: white;
  font-size: 30px;
  justify-content:center;
  background-color: black;

}
.student-card-points {
  /* flex-grow:1; */
  text-align:center;
  font-size:55px;
  font-weight:bold;
  /* justify-content:center; */
  align-self:center;

}

.student-card-popup {
  display:flex;
  justify-content:center;
  align-items:center;
  position:relative;
  bottom:0;
}

.student-head {
  background-color:black;
  background-image: radial-gradient(circle at top left, rgb(95, 94, 94) 10%, black );
  height:65%;
  width:30%;
  border-radius:50%;
  margin-bottom:2px;
}
.student-head-button-container {
  display:flex;
  width:100%;
  height:100%;
  align-items:flex-end;
  position:relative;
  justify-content:center;
}
.student-body {
  background-color: black;
  background-image: radial-gradient(circle at top left, rgb(95, 94, 94) 10%, black );

  height: 28%;
  width: 65%;
  border-radius: 50px 50px 0% 0%
}
.pts-button-container{
  position:absolute;
  display:flex;
  height:100%;
  width:100%;
  align-items:flex-start;
  justify-content:space-between;
}
.pts-buttons {
 display:none;
 max-height:20px;
 height:20px;
}

.student-card-container:hover .pts-buttons{
 display:inline;
}




.student-card-count{
  flex-grow:1;
  position:absolute;
}

.student-count-style-positive{
  color: #082308;
  background-image: radial-gradient(#8ee28e, #8ee28e45, transparent);
  border-radius: 50%;
  width: 30px;
}
.student-count-style-negative{

  background-image: radial-gradient(#ff7a70, #ff7a7080, transparent);
  color: black;
  border-radius: 50%;
  width: 30px
}
.student-count-style-neutral{
  /* text-shadow: 2px 2px 10px #a7aba76b; */
  color: #265426;
}

.desk-button-main-container {
  display:flex;
  /* justify-content:center; */
  position:relative;
  /* width: 160px; */
  justify-content: center;
  align-items: end;
}
.desk-button-main-container p{
  /* background-image: radial-gradient(#59ff5973, transparent); */
  margin: 0;
}

.desk-button-container {
  /* display:none; */
  max-height:20%;
  height:20%;
  position:absolute;
  /* margin-left:-55%; */
  width: 100%;
  /* margin-left: -10px; */
  display: flex;
  justify-content: flex-end;
  align-items: end
  
 }
 .student-card-container:hover .desk-button-container{
  justify-content: space-between
 
 }
.desk-button {
  display:none;
  margin-left: -10%;
  margin-right:-20%
}

.student-card-container:hover .desk-button{
 display:inline;
}
.student-name{
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  padding-bottom: 0;
  overflow: hidden;
}
.name-large{
  font-size: 24px;
}
.name-medium{
  font-size: 22px;

}
.name-small{
  font-size: 18px;

}
.name-xsmall{
  font-size: 17px;

}
.name-xxsmall{
  font-size: 16px;

}

@media(max-width:900px){
  .student-head-button-container{
    width:120%
  }

  .desk{
    font-size:1rem;
  }
  .name-large{
    font-size: 17px;
  }
  .name-medium{
    font-size: 16px;
  
  }
  .name-small{
    font-size:15px;
  }
  .name-xsmall{
    font-size:14px;
  }
  .name-xxsmall{
    font-size:13px;
  }
  .desk-button-main-container{
    margin-bottom:32px;
    margin-top:-10px;
  }
  .desk-button-main-container p{
    margin-top:10px;
    font-size: 14px;
  }
  .student-card-container{
    height: 95px;
    width:95px;
  }

}