@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
.classes-main-container{
  
}
.classes-container {
  background: url("../app-files/images/tan-floor.jpg"); 
  background-repeat: repeat;
  background-size: 700px 500px;
  height:auto;
  /* background: url("../app-files/images/warmwood-background.jpg"); 
  background-repeat: repeat;
  background-size: 500px 300px;

  background: url("../app-files/images/tantile-background.jpg"); 
  background-repeat: repeat;
  background-size: 100px 80px; */

}
.classes-container h1{
  text-align:center;
}
.no-classes-container{
  margin-top:100px;


}
.no-classes-container h1{
  text-align:center;
}
.no-classes-logo-container{
  display:flex;
  justify-content:center;
  width:100vw;
}
.droppable-container{
  display: flex;
  margin: 0px 50px 50px 50px;
}

.row-container {
  display:flex;
  flex-direction:column;
  
}
.row-main-container {
  display:flex;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 70px;
  background-image:  linear-gradient( 
    45deg
     ,rgb(255 255 255 / 90%), rgb(255 255 255 / 90%), rgb(255 255 255 / 91%))

}
.group-main-container {
  height: calc(100vh - 121px);
  padding-bottom: 70px;
  background-image:  linear-gradient( 
    45deg
     ,rgb(255 255 255 / 90%), rgb(255 255 255 / 90%), rgb(255 255 255 / 91%))
}
.row {
  display:flex;
  justify-content:flex-start;

}
.group7 {
  display:flex;
  flex-direction:column;
  height:550px;
  margin:20px 30px 10px 30px;
  width:300px;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items:flex-start;
}
.group-container7 {
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  width:100%;

}
.group4 {
  display:flex;
  flex-direction:column;
  padding:10px 0px 0px 0px;
  justify-content:flex-start;
  align-items:flex-start;

}
.group-container4 {
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  padding-bottom:10px;

}
.group5, .group6 {
  display:flex;
  flex-direction:column;
  min-width: 145px;
  padding:10px 0px 0px 0px;
  flex-wrap:wrap;
  justify-content:flex-start;
  align-items:flex-start;
}
.group-container5, .group-container6 {
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  padding-bottom:50px;

}



.small-icon{
  height:130px !important;
  width:120px !important;
}
.small-buttons {
  margin-left: -10% !important;
  margin-right:-27% !important;
}
.small-font {
  font-size:1rem !important;
}
.small-group4 {
  /* height:300px !important; */
  /* width:250px !important; */
  /* margin:0px 30px !important; */
}
.small-group5, .small-group6 {
  height:390px !important;
  /* width:250px !important; */
  /* margin:0px 30px 30px 30px !important; */
}
.small-group7{
  height:630px !important;
  width:250px !important;
  /* margin:0px 30px !important; */
}
.select-form{
  width:120px !important;
}


.student-card-container { /* changes style under card */
  width:145px;
  height:150px;
  padding: 0px;
  display: flex;
  flex-direction:column;
  align-items:center;
}

.classes-cb-list-container{
  display:flex;
  justify-content:center;
  width:1500px;
  overflow-y:visible;
}

.temp-container {
  margin-bottom:100px
}


/* studentCard */
.drag{
  width:100%;
  height:100%;
}
 .student-icon-container {
   display:flex;
   height:50%;
   flex-direction:column;
   justify-content:center;
   align-items:center;
   background-color: transparent;
   width:100%;

 }



/* Desk & Desk Buttons */
 .desk-top {
   height:6%;
   color:rgb(0, 0, 0);
   text-align:center;
   font-size:20px;
   font-weight:bold;
   width:93%;
   border: 3px outset rgb(95 94 94 / 44%);
   /* opacity:130%; */
   border-radius:7px;
   background-color:rgb(77, 63, 63)
 }
 .blank-desk-top {
   background-color:rgb(209, 208, 208);
   /* border:2px solid grey; */
 }
 .desk {
   display:block;
   height:45%;
   width:85%;
   /* opacity:80%; */
   background-color:rgb(149, 181, 223);
   color:black;
   text-align:center;
   font-weight:bold;
   font-size:20px;
   margin:0 9px 0px 9px;
   border-bottom-left-radius:3px;
   border-bottom-right-radius:3px;
   border-right: 3px outset #80808063;
   border-left: 3px outset #80808063;
   border-bottom: 3px outset #80808063;
   white-space: nowrap;
   box-shadow:5px 3px 7px 2px grey;
   font-family:'Quicksand';

 }

 .blank-desk {
  background-color:rgb(248, 252, 255);
  /* border:2px solid grey; */
  box-shadow:3px 2px 7px 2px rgb(177, 175, 175);
 }

.testing {
  background-color:rgb(117, 117, 117)
}

.addNewStu-modal{
  display: flex;
}

.transition-classes-enter {
  opacity: 0;
}
.transition-classes-active {
  opacity: 1;
  transition: opacity 200ms;
}
.transition-classes-exit {
  opacity: 1;
}
.transition-classes-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

@media(max-width:900px){
  .droppable-container{
    margin: 0px 5px 15px 5px
  }
  .small-icon{
    width:95px!important;
    height:95px!important;
  }
  .group56{
    min-width:100px;
  }
  .group7{
    width:100px;
    min-width:100px;
    margin:0px;
    height:415px;
  }
  .addNewStu-modal{
    padding:0rem;
  }
  .group-container4{
    padding-bottom:50px;
  }
  .classes-container{
    padding-bottom: 50px;
  }
}

@media(max-width:500px){

}