.main-container {
  height:100vh;
}


.blank-student {
  width:180px;
  height:180px;
  /* padding:0px 10px 0px 10px; */
  box-shadow: 10px 10px 10px grey;
  border-radius: 20px;
}

.grid-row {
  display:flex;
  justify-content:center;
}
.name-display-container {
  display:flex;
  flex-direction:column;
}
.name-display-row {
  display:flex;
}
.icon {
  padding:5px;
}
